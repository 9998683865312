@import "~rivals/styles/variables";

@mixin hide-on-tablet-and-mobile {
  @include sizes-ending-with($screen-tablet-max) {
    display: none;
  }
}

@mixin hide-on-desktop {
  @include sizes-starting-with($screen-sm-desktop-min) {
    display: none;
  }
}

@mixin hide-on-large-desktop {
  @include sizes-starting-with($screen-lg-desktop-min) {
    display: none;
  }
}

@mixin hide-on-all-but-large-desktop {
  @include sizes-ending-with($screen-sm-desktop-max) {
    display: none;
  }
}

@mixin hide-on-tablet-and-desktop {
  @include sizes-starting-with($screen-tablet-min) {
    display: none;
  }
}

@mixin hide-on-mobile {
  @include sizes-ending-with($screen-lg-mobile-max) {
    display: none;
  }
}

@mixin hide-on-sm-mobile {
  @include sizes-ending-with($screen-mobile-max) {
    display: none;
  }
}

@mixin hide-on-all-but-sm-mobile {
  @include sizes-starting-with($screen-lg-mobile-min) {
    display: none;
  }
}

@mixin hide-on-desktop-and-mobile {
  @include hide-on-desktop;
  @include hide-on-mobile;
}

@mixin hide-on-tablet-only {
  @include sizes-between($screen-tablet-min, $screen-tablet-max) {
    display: none;
  }
}

@mixin hide-on-sm-desktop-only {
  @include sizes-between(
    $screen-sm-desktop-min,
    $screen-sm-desktop-updated-max
  ) {
    display: none;
  }
}
