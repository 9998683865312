@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module.scss";

$alert-text-line-height-mobile-tablet: 1.2;

.alertWrapper {
  position: relative;
  width: 100%;

  .closeButton {
    height: 32px;
    padding: $spacing-8;
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;

    > svg {
      g {
        fill: $bone;
      }
    }
  }
}

.alertBanner {
  color: $bone;
  display: flex;
  flex-direction: column;
  grid-row-gap: $spacing-8;
  padding: $spacing-8 $spacing-12;
  @include sizes-starting-with($screen-tablet-min) {
    padding: $spacing-12 $spacing-24;
  }
  @include sizes-starting-with($screen-sm-desktop-min) {
    align-items: center;
    display: grid;
    flex-direction: row;
    grid-gap: $spacing-24;
    grid-template-columns: max-content 1fr auto;
  }

  .alertText {
    color: $bone;
    flex-grow: 1;

    h1,
    h2 {
      margin: 0;
    }

    h1 {
      @include font-extended-headline-H3;
      line-height: $alert-text-line-height-mobile-tablet;
      @include sizes-starting-with($screen-tablet-min) {
        @include font-extended-headline-H2;
        line-height: $alert-text-line-height-mobile-tablet;
      }
      @include sizes-starting-with($screen-med-desktop-min) {
        @include font-extended-headline-H1;
      }
    }

    h2 {
      @include font-primary-body-B5;
      line-height: $alert-text-line-height-mobile-tablet;
      @include sizes-starting-with($screen-tablet-min) {
        @include font-primary-body-B4;
        line-height: $alert-text-line-height-mobile-tablet;
      }
      @include sizes-starting-with($screen-sm-desktop-min) {
        @include font-primary-body-B4;
      }
    }
  }
}

.alertLink {
  position: relative;
}

.buttonWrapper {
  @include font-extended-headline-H7;
  align-items: center;
  color: $bone;
  display: flex;
  height: 19px;
  width: 100%;
  @include sizes-starting-with($screen-tablet-min) {
    @include font-extended-headline-H5;
  }
  @include sizes-starting-with($screen-sm-desktop-min) {
    justify-content: center;
    margin-bottom: 0;
    min-width: 70px;
  }
}

.seeMoreText {
  margin-right: $spacing-4;
}

.dismissableButton {
  @include sizes-starting-with($screen-sm-desktop-min) {
    margin-right: $spacing-24;
  }
}
