.spinner {
  margin-bottom: 12px;
  min-height: 60px;
  min-width: 60px;
  position: relative;

  &::after,
  &::before {
    content: "";
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  @keyframes spin {
    from {
      transform: translate(-50%, -50%) rotate(0turn);
    }

    to {
      transform: translate(-50%, -50%) rotate(1turn);
    }
  }

  &::before {
    animation: spin 1.55s linear 0s infinite;
    background: url("/static/icons/icons_loader_outside.svg");
    height: 60px;
    width: 60px;
  }
}
