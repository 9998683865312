@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/hide-elements";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module.scss";

$banner-text-line-height: 1.2;
$desktop-button-width: 234px;
$desktop-button-height: 40px;
$mobile-button-width: 153px;
$mobile-button-height: 27px;

.promotionBannerWrapper {
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include sizes-ending-with($screen-tablet-max) {
    margin-left: -$spacing-12;
    padding: $spacing-12 0;
    width: 100vw;
  }

  .noiseAccent {
    background: radial-gradient(transparent 0%, $eye-black-90 40%),
      url("/static/images/texture_light_noise.svg");
    filter: brightness(0%);
    height: 300%;
    left: 50%;
    margin-left: -100%;
    position: absolute;
    top: -35%;
    width: 150%;
    z-index: 1;
    @include sizes-starting-with($screen-tablet-min) {
      margin-left: -50%;
      top: -35%;
      width: 100%;
    }
    @include sizes-starting-with($screen-sm-desktop-min) {
      margin-left: -75%;
      top: -40%;
      width: 150%;
    }
  }

  .nationalStatic {
    background: radial-gradient(transparent 5%, $eye-black-90 50%),
      url("/static/images/texture_light_noise.svg");
    filter: brightness(25%);
  }

  .leftAccent,
  .rightAccent {
    bottom: 0;
    height: 360px;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 1277px;
    z-index: 3;
  }

  .leftAccent {
    @include hide-on-mobile;
    right: min(
      calc(100% - 217px),
      calc(50% + calc(#{$screen-lg-desktop-min} / 2))
    );
  }

  .rightAccent {
    left: min(
      calc(100% - 280px),
      calc(50% + calc(#{$screen-lg-desktop-min} / 2))
    );
  }

  .backgroundLogoAccent {
    bottom: 0;
    height: 217px;
    left: 50%;
    margin: auto 0 auto (calc(1437px / -2));
    position: absolute;
    top: 0;
    width: 1437px;
    z-index: 2;
  }
}

.promotionBanner {
  color: $bone;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: $screen-sm-desktop-min;
  order: 1;
  padding: $spacing-12;
  width: 100%;
  z-index: 4;
  @include sizes-starting-with($screen-tablet-min) {
    flex-direction: row;
    min-height: 120px;
    padding: $spacing-12 $spacing-24;
  }

  .promotionText {
    color: $bone;
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 1;
    @include sizes-starting-with($screen-tablet-min) {
      padding-right: $spacing-12;
      width: calc(100% - #{$mobile-button-width});
    }
    @include sizes-starting-with($screen-sm-desktop-min) {
      padding-right: $spacing-24;
      width: calc(100% - #{$desktop-button-width});
    }

    h1 {
      @include font-primary-bold-SB4;
      line-height: $banner-text-line-height;
      margin: 0;
      order: 1;
      @include sizes-starting-with($screen-tablet-min) {
        @include font-primary-bold-SB3;
        line-height: $banner-text-line-height;
      }
    }

    h2 {
      @include font-primary-body-B7;
      line-height: $banner-text-line-height;
      margin-top: $spacing-8;
      order: 2;
    }
  }

  .promotionButton {
    @include font-primary-bold-SB7;
    background-color: $transparent;
    border: 1px solid $bone;
    color: $bone;
    height: $mobile-button-height;
    line-height: calc(#{$mobile-button-height} - 2px);
    margin-top: $spacing-12;
    order: 2;
    text-align: center;
    width: $mobile-button-width;
    @include sizes-starting-with($screen-tablet-min) {
      margin: auto 0;
    }
    @include sizes-starting-with($screen-sm-desktop-min) {
      @include font-extended-headline-H5;
      height: $desktop-button-height;
      line-height: calc(#{$desktop-button-height} - 2px);
      width: $desktop-button-width;
    }
  }

  .nationalButton {
    border-color: $star-90;
    color: $star;
  }
}
