@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables.scss";

.container {
  border-bottom: 1px solid $bone-30;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: $spacing-12;
  padding: $spacing-12 0;
  width: width(100% - #{$spacing-24});

  .authorInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .authorContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;

      .authorImage {
        margin-right: $spacing-12;
      }

      .authorName {
        @include font-primary-medium-S4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include sizes-ending-with($screen-lg-mobile-max) {
          @include font-primary-medium-S5;
        }
      }

      .authorTitle {
        @include font-primary-body-B5;
        @include sizes-ending-with($screen-lg-mobile-max) {
          @include font-primary-body-B6;
        }
      }

      .twitterHandle {
        @include font-primary-bold-SB6;
        align-items: center;
        color: $hydro;
        display: inline-flex;
        text-decoration: none;
      }
    }
  }
}

.authorBioDrawer {
  height: 25px;
  width: 25px;
}

.authorBio {
  @include font-primary-body-B4;
  display: flex;
  margin-top: $spacing-12;
}
